import { ArrowForwardIcon, Button, Text, Link, useMatchBreakpoints, useIsomorphicEffect } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import { memo, useMemo, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { jihua, jihuaM } from './images'
import * as S from './Styled'

const RightImg = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 32px;
  height: 179px;
`
const RightBg = styled.div`
  & img {
    border-radius: 0px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 182px !important;
    }
    ${({ theme }) => theme.mediaQueries.md} {
      width: 1064px !important;
      height: 192px !important;
      border-radius: 32px !important;
    }
  }
`
const RightWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 50%;
  margin-left: -173px;
`
const RightMWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 50%;
  margin-left: -145px;
`
const Header = styled(S.StyledHeading)`
  font-size: 20px;
  min-height: 44px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 25px;
    min-height: auto;
  }
`

const HEADING_ONE_LINE_HEIGHT = 27

const CooperateBanner = () => {
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { isDesktop, isMobile } = useMatchBreakpoints()
  const { isDark } = useTheme()

  const perpetualUrl = useMemo(
    () => `https://t.me/BunngSwap`,
    [],
  )
  const headerRef = useRef<HTMLDivElement>(null)

  return (
    <S.Wrapper>
      <S.Inner>
        <RightImg>
          {!isMobile ? (
            <RightBg>
              <Image src={jihua} alt="HezuoBanner" width={1064} height={192} placeholder="blur" />
            </RightBg>
          ) : (
            <RightBg>
              <Image src={jihuaM} alt="HezuoBanner" width={392} height={185} placeholder="blur" />
            </RightBg>
          )}
        </RightImg>
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(CooperateBanner)
